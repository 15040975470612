<template>
  <div
    class="busCard"
    @mousemove="isHover = true"
    @mouseleave="isHover = false"
    :style="busCardStyle"
    @click="
      $vuetify.breakpoint.smAndDown ? $emit('showDetailBusModal', bus) : ''
    "
  >
    <div style="padding: 16px 0px 0px 16px">
      <div class="busQuantitySeatsBox">
        {{ bus.seats }} {{ "seats_label" | localize }}
      </div>
      <p class="busModel">{{ bus.manufacturer }} {{ bus.model }}</p>
      <v-expand-transition v-if="!$vuetify.breakpoint.smAndDown">
        <v-btn
          height="40px"
          class="detailBtn"
          v-if="isHover"
          @click="$emit('showDetailBusModal', bus)"
          >{{ "detail_btn" | localize }}</v-btn
        >
      </v-expand-transition>
    </div>
  </div>
</template>

<script>
export default {
  data: () => ({
    isHover: false,
  }),
  props: {
    bus: {
      type: Object,
      required: true,
    },
  },
  computed: {
    busCardStyle() {
      return {
        backgroundImage:
          this.isHover && !this.$vuetify.breakpoint.smAndDown
            ? `linear-gradient(
              0deg,
              rgba(0, 0, 0, 0.8) -3.27%,
              rgba(0, 0, 0, 0) 165.41%
            ), url(${this.bus.images?.[0]?.path})`
            : `url(${this.bus.images?.[0]?.path})`,
        backgroundSize: "cover",
      };
    },
  },
};
</script>

<style scoped>
.busCard {
  height: 320px;
  border-radius: 10px;
  position: relative;
}
@media only screen and (min-width: 1000px) {
  .busCard:hover .busModel {
    bottom: 60px;
  }
  .busCard:hover .busQuantitySeatsBox {
    color: #1b1b1b;
    background: #fed500;
  }
}
.busQuantitySeatsBox {
  display: grid;
  place-items: center;
  padding: 4px 8px;
  border-radius: 10px;
  background: #989898;
  color: #fafafa;
  font-family: "MacPaw Fixel";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  width: max-content;
}
.busModel {
  color: var(--Neutrals, #fff);
  font-family: "MacPaw Fixel";
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  position: absolute;
  bottom: 22px;
}
.quantitySeats {
  color: #fff;
  font-family: "MacPaw Fixel";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  bottom: 0px;
}
.detailBtn {
  width: 75%;
  border-radius: 10px;
  background: #144fa9 !important;
  text-transform: none;
  color: #fff;
  font-family: "MacPaw Fixel";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  bottom: 16px;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  text-align: center;
}
</style>
