<template>
  <div
    class="px-0"
    style="box-sizing: border-box"
    :style="
      $vuetify.breakpoint.smAndDown ? 'padding: 40px 0px' : 'padding:70px 0px'
    "
  >
    <div
      class="iconBackground swiper-button-prev-bus"
      style="left: 8svw; margin-top: 230px"
      slot="button-prev"
      v-if="!$vuetify.breakpoint.smAndDown"
    >
      <img
        src="@/assets/img/iconsSvg/swiperRightIcon.svg"
        alt="right icon"
        style="transform: rotate(180deg)"
      />
    </div>
    <div
      style="
        right: calc(9svw - 16px);
        margin-top: 230px;
        box-shadow: 12px 0px 20px 0px rgba(0, 0, 0, 0.1);
      "
      v-if="!$vuetify.breakpoint.smAndDown"
      class="iconBackground swiper-button-next-bus"
      slot="button-next"
    >
      <img src="@/assets/img/iconsSvg/swiperRightIcon.svg" alt="right icon" />
    </div>
    <v-main>
      <v-container>
        <v-row no-gutters align="center">
          <div
            style="
              width: 12px;
              height: 12px;
              background: #144fa9;
              border-radius: 50%;
            "
          />
          <h2
            style="
              color: var(--Black, #1b1b1b);
              font-family: 'MacPaw Fixel';
              font-size: 32px;
              font-style: normal;
              font-weight: 600;
              line-height: normal;
              margin-left: 8px;
            "
          >
            {{ "our_fleet_section" | localize }}
          </h2>
          <v-row
            no-gutters
            justify="end"
            align="center"
            v-if="!$vuetify.breakpoint.smAndDown"
          >
            <button class="showAllBtn" @click="$router.push('/our_fleet')">
              {{ "show_all_btn" | localize }}
            </button>
          </v-row>
        </v-row>
        <swiper
          ref="mySwiper"
          :options="swiperOptions"
          style="margin-top: 40px"
          v-if="!$vuetify.breakpoint.smAndDown"
        >
          <swiper-slide
            v-for="bus in busList"
            :key="bus.id"
            :cssMode="true"
            :navigation="true"
            :pagination="true"
            :mousewheel="true"
            :keyboard="true"
            :modules="swiperOptions.modules"
          >
            <bus-card :bus="bus" @showDetailBusModal="showDetailBusModal" />
          </swiper-slide>
        </swiper>
        <swiper
          v-else
          ref="mySwiper"
          :options="swiperMobileOptions"
          style="margin-top: 40px"
        >
          <swiper-slide
            v-for="bus in busList"
            :key="bus.id"
            :cssMode="true"
            :navigation="true"
            :pagination="true"
            :mousewheel="true"
            :keyboard="true"
            :modules="swiperOptions.modules"
          >
            <bus-card :bus="bus" @showDetailBusModal="showDetailBusModal" />
          </swiper-slide>
        </swiper>
        <submit-outline-button
          @close="$router.push('/our_fleet')"
          style="margin-top: 20px"
          v-if="$vuetify.breakpoint.smAndDown"
          >{{ "show_all_btn" | localize }}</submit-outline-button
        >
      </v-container>
    </v-main>
    <bus-detail-modal
      v-if="isShowDetailBusModal"
      :visible="isShowDetailBusModal"
      :bus="bus"
      @close="isShowDetailBusModal = false"
    />
  </div>
</template>

<script>
import swiperOptionMixinVue from "../../../../mixins/swiperOptionMixin";
import busService from "../../../../requests/Bus/busService";
import SubmitOutlineButton from "../../../UI/Buttons/submitOutlineButton.vue";
import busCard from "../../../UI/Cards/busCard.vue";
import BusDetailModal from "./busDetailModal.vue";
export default {
  components: { busCard, BusDetailModal, SubmitOutlineButton },
  mixins: [swiperOptionMixinVue],
  data: () => ({
    swiperOptions: {
      slidesPerView: 4,
      spaceBetween: 16,
      navigation: {
        nextEl: ".swiper-button-next-bus",
        prevEl: ".swiper-button-prev-bus",
      },
    },
    swiperMobileOptions: {
      slidesPerView: 1.2,
      spaceBetween: 16,
      navigation: {
        nextEl: ".swiper-button-next-bus",
        prevEl: ".swiper-button-prev-bus",
      },
    },
    bus: {},
    busList: [],
    isShowDetailBusModal: false,
  }),
  mounted(){
    this.getBuses();
  },
  methods: {
    showDetailBusModal(bus) {
      this.bus = bus;
      this.isShowDetailBusModal = true;
    },
    async getBuses() {
      await busService.getBusesForMain().then((res) => {
        if (res.status == "Success") {
          this.busList = res.data;
        }
      });
    },
  },
};
</script>

<style scoped>
.iconBackground {
  width: 48px;
  height: 48px;
  display: grid;
  place-items: center;
  background: #fafafa;
  box-shadow: -12px 0px 20px 0px rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  position: absolute;
  z-index: 10;
  cursor: pointer;
  user-select: none;
}
.showAllBtn {
  color: var(--Black, #1b1b1b);
  font-family: "MacPaw Fixel";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  border-radius: 10px;
  border: 1px solid rgba(20, 79, 169, 0.5);
  width: 180px;
  height: 40px;
  display: grid;
  place-items: center;
}
</style>