

import { Pagination, Navigation } from "vue-awesome-swiper";
export default {
  data: () => ({
    swiperOptions: {
      pagination: {
        el: ".swiper-pagination",
      },
      navigation: {
        nextEl: ".swiper-button-next-banner",
        prevEl: ".swiper-button-prev-banner",
      },
      modules: [Navigation, Pagination],
    },
  }),
};
