<template>
  <v-dialog v-model="visibility" width="580px">
    <div
      :style="
        $vuetify.breakpoint.smAndDown
          ? 'position:relative; width: 100%;overflow-x:hidden'
          : ''
      "
    >
      <v-row
        v-if="$vuetify.breakpoint.smAndDown"
        no-gutters
        style="
          position: absolute;
          width: 100%;
          padding: 0px 10px;
          margin-top: 156px;
        "
        justify="space-between"
      >
        <div
          class="iconBackground swiper-button-prev-detail-bus"
          style="position: static !important"
          slot="button-prev"
        >
          <img
            src="@/assets/img/iconsSvg/swiperRightIcon.svg"
            alt="right icon"
            style="transform: rotate(180deg)"
          />
        </div>
        <div
          style="
            box-shadow: 12px 0px 20px 0px rgba(0, 0, 0, 0.1);
            position: static !important;
          "
          class="iconBackground swiper-button-next-detail-bus"
          slot="button-next"
        >
          <img
            src="@/assets/img/iconsSvg/swiperRightIcon.svg"
            alt="right icon"
          />
        </div>
      </v-row>
      <div
        class="iconBackground swiper-button-prev-detail-bus"
        style="margin-top: 176px; margin-left: 20px"
        slot="button-prev"
        v-if="!$vuetify.breakpoint.smAndDown"
      >
        <img
          src="@/assets/img/iconsSvg/swiperRightIcon.svg"
          alt="right icon"
          style="transform: rotate(180deg)"
        />
      </div>
      <div
        style="
          margin-top: 176px;
          margin-left: 512px;
          box-shadow: 12px 0px 20px 0px rgba(0, 0, 0, 0.1);
        "
        class="iconBackground swiper-button-next-detail-bus"
        slot="button-next"
        v-if="!$vuetify.breakpoint.smAndDown"
      >
        <img src="@/assets/img/iconsSvg/swiperRightIcon.svg" alt="right icon" />
      </div>
      <div class="modalBackground">
        <swiper ref="mySwiper" :options="swiperOptions" class="busImgSwiper">
          <swiper-slide
            v-for="image in bus.images"
            :key="image.id"
            :cssMode="true"
            :navigation="true"
            :pagination="true"
            :mousewheel="true"
            :keyboard="true"
            :modules="swiperOptions.modules"
          >
            <img
              :src="image.path"
              width="100%"
              :height="$vuetify.breakpoint.smAndDown ? '360px' : '400px'"
              style="object-fit: cover"
            />
          </swiper-slide>
          <div class="swiper-pagination" slot="pagination"></div>
        </swiper>
        <div style="padding: 16px">
          <h3 class="busModelName">{{ bus.manufacturer }} {{ bus.model }}</h3>
          <v-row no-gutters align="center" style="margin-top: 20px">
            <v-row no-gutters align="center" style="margin-bottom: 12px">
              <span class="busText">{{
                "bus_graduation_year_label" | localize
              }}</span>
              <div class="busDescriptionValue">{{ bus.year }}</div>
            </v-row>
            <v-row no-gutters align="center" style="margin-bottom: 12px">
              <span class="busText">{{ "bus_color_label" | localize }}</span>
              <div class="busDescriptionValue">
                {{ bus?.translations?.color }}
              </div>
            </v-row>
            <v-row no-gutters align="center" style="margin-bottom: 12px">
              <span class="busText">{{
                "bus_quantity_seats_label" | localize
              }}</span>
              <div class="busDescriptionValue">{{ bus.seats }}</div>
            </v-row>
            <v-row no-gutters align="center" style="margin-bottom: 12px">
              <span class="busText">{{
                "bus_floor_area_label" | localize
              }}</span>
              <div class="busDescriptionValue">
                {{ bus?.translations?.floors }}
              </div>
            </v-row>
            <v-row no-gutters align="center" style="margin-bottom: 12px">
              <span class="busText">{{
                "bus_govermant_number_label" | localize
              }}</span>
              <div class="busDescriptionValue">{{ bus.government_number }}</div>
            </v-row>
          </v-row>
          <p class="busTitle" style="margin: 20px 0px 0px 0px">
            {{ "description_label" | localize }}
          </p>
          <p class="busText" style="margin: 12px 0px 0px 0px">
            {{ bus?.translations?.description }}
          </p>
          <p class="busTitle" style="margin: 20px 0px 0px 0px">
            {{ "bus_additional_services_label" | localize }}
          </p>
          <v-row no-gutters align="center" style="margin-top: 12px">
            <button
              class="busServiceBox"
              v-for="service in bus.services"
              :key="service.id"
            >
              {{ service?.translations?.name }}
            </button>
          </v-row>
          <v-row no-gutters align="center">
            <v-col
              style="padding-right: 10px"
              v-if="!$vuetify.breakpoint.smAndDown"
            >
              <submit-outline-button @close="$emit('close')">{{
                "close_btn" | localize
              }}</submit-outline-button></v-col
            >
            <v-col
              :style="$vuetify.breakpoint.smAndDown ? '' : 'padding-left: 10px'"
            >
              <submit-button @click="$router.push('/rent_bus')">{{
                "rent_bus_btn" | localize
              }}</submit-button></v-col
            >
            <v-col
              cols="12"
              style="margin-top: 20px"
              v-if="$vuetify.breakpoint.smAndDown"
            >
              <submit-outline-button @close="$emit('close')">{{
                "close_btn" | localize
              }}</submit-outline-button></v-col
            >
          </v-row>
        </div>
      </div>
    </div>
  </v-dialog>
</template>

<script>
import modalMixin from "../../../../mixins/modalMixin";
import swiperOptionMixin from "../../../../mixins/swiperOptionMixin";
import submitButton from "../../../UI/Buttons/submitButton.vue";
import SubmitOutlineButton from "../../../UI/Buttons/submitOutlineButton.vue";

export default {
  components: { submitButton, SubmitOutlineButton },
  mixins: [modalMixin, swiperOptionMixin],
  data: () => ({
    swiperOptions: {
      navigation: {
        nextEl: ".swiper-button-next-detail-bus",
        prevEl: ".swiper-button-prev-detail-bus",
      },
    },
  }),
  props: {
    bus: {
      require: true,
    },
  },
  mounted() {
    this.$refs.mySwiper.initSwiper();
    setTimeout(() => {
      this.$refs.mySwiper.updateSwiper();
      this.$refs.mySwiper.autoReLoopSwiper();
    }, 500);
  },
};
</script>

<style scoped>
.modalBackground {
  border-radius: 10px;
  background: #fff;
}
.busModelName {
  color: #1b1b1b;
  font-family: "MacPaw Fixel";
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin: 20px 0px 0px 0px;
}
.busTitle {
  color: #1b1b1b;
  font-family: "MacPaw Fixel";
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.busText {
  color: #1b1b1b;
  font-family: "MacPaw Fixel";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.busDescriptionValue {
  border-radius: 10px;
  background: rgba(20, 79, 169, 0.1);
  padding: 4px 8px;
  display: grid;
  place-items: center;
  color: #144fa9;
  font-family: "MacPaw Fixel";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  width: max-content;
  height: 30px;
  margin: 0px 12px 0px 12px;
}
.busServiceBox {
  border-radius: 10px;
  background: rgba(20, 79, 169, 0.1);
  padding: 4px 8px;
  width: max-content;
  color: #1b1b1b;
  font-family: "MacPaw Fixel";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-right: 20px;
  margin-bottom: 20px;
}
.iconBackground {
  width: 48px;
  height: 48px;
  display: grid;
  place-items: center;
  background: #fafafa;
  box-shadow: -12px 0px 20px 0px rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  position: absolute;
  z-index: 20;
  cursor: pointer;
  user-select: none;
}
</style>
<style>
.busImgSwiper .swiper-pagination-bullet {
  background: #144fa9 !important;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  position: static;
}
</style>